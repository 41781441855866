.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.vertical-text {
  text-align:center;
  white-space:nowrap;
  transform-origin:50% 50%;
  transform: rotate(180deg);

}
.vertical-text:before {
  content:'';
  padding-top:110%;/* takes width as reference, + 10% for faking some extra padding */
  display:inline-block;
  vertical-align:middle;
}

.footer-box{
  display:flex;flex-flow:column;
  padding-bottom:20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.expertise-card{
  text-align: center;
  display: flex;flex-flow: column;
  padding:15px;
  background-color: white;
  border:0px solid black;
  padding:10px;
  padding-bottom:25px;
  padding-top:25px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

.fun-heading{
  max-height: 35px;
  color:gray;
  font-family: monospace;
}
.fun-heading > img{
  height: 35px;width: auto;
}

.expertise-card img {
  height: 100px;
  width:100px;
  margin: 0 auto;
}

.subpackage {
  margin-top:10px;
}

strong {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.125rem;
    left: -0.5rem;
    right: -0.5rem;
    height: 0.75rem;
/* 
    // Position the line behind the text so that
    // it is still easily readable */
    z-index: -1;

    /* // The SVG is added as an SVG background image */
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/664131/underline.svg');
    background-repeat: no-repeat;

    /* // This allows the SVG to flex in size to fit
    // any length of word. If the word is short,
    // the SVG will be stretched vertically, if it
    // is long, the SVG will be stretched horizontally.
    // The jagged nature of this particular SVG works
    // with this transforming. */
    background-size: cover;
  }
}

p > strong {
  font-weight: 400;  

  &::after {
    /* Specific positioning for smaller text */
    bottom: -0.2rem;
    height: 0.5rem;
    left: -0.25rem;
    right: -0.25rem;
  }
}

/* // ---------------------------------
// Other properties for the demo, not related to
// the underlined text */

/* h1, p {
  font-family: "dosis", sans-serif;
  max-width: 800px;
  margin: 0 5% 1rem;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
} */

p {
  font-size: 1.125rem;
}

.gradient-text {
  background: linear-gradient(to right, #ff0000, #00ff00, #0000ff);
  -webkit-background-clip: text; /* For webkit browsers (Safari/Chrome) */
  background-clip: text;
  padding:15px;
  color: transparent; /* Hide the original text */
}

